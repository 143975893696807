import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Button, Container } from 'reactstrap';
import Layout from '../../../components/Layout';
import SEO from '../../../components/Seo';
import Jumbotron from '../../../components/Jumbotron';
import { SECONDARY } from '../../../utils/colors';
import Thumb from '../../../assets/svgs/jobs.svg';

const Desiner = ({ location }) => (
  <Layout theme="blue" location={location}>
    <SEO title="UX/UI Designer Sênior na Central do Frete" />
    <Jumbotron
      Image={Thumb}
      background={SECONDARY}
      title="UX/UI Designer Sênior"
      subtitle="Venha ajudar a descomplicar a forma como as empresas contratam fretes"
    />
    <Container className="pb-5 pt-4">
      <h4>Prazer! Somos a Central do Frete</h4>
      <p>
        Nós somos uma startup de tecnologia que está transformando a maneira
        como as empresas contratam fretes.
      </p>
      <p>
        Em um mundo onde descomplicar é a palavra de ordem, chegamos para criar
        uma experiência mais satisfatória tanto para quem compra quanto para
        quem vende.
      </p>
      <h4>Local</h4>
      <p>
        Remoto: Todo o time de desenvolvimento trabalha remoto (Apenas a equipe
        de operação fica em São Caetano do Sul/SP)
      </p>
      <h4>Modalidade de contrato</h4>
      <p>Contrato PJ de prestação de serviços.</p>
      <h4>Estamos Buscando Uma Pessoa</h4>
      <ul>
        <li>Hábil utilizando Figma;</li>
        <li>Que tenha experiência com Material Design;</li>
        <li>Que trabalhe com autonomia para priorizar tarefas;</li>
        <li>
          Que garanta a fluidez na jornada do usuário, para que ele tenha uma
          experiência incrível desde o primeiro contato conosco, aquisição do
          nosso produto até a retenção;
        </li>
        <li>Que possua experiência prévia como UX ou UI Designer;</li>
        <li>Com forte senso estético;</li>
        <li>
          Com experiência em design de interface (comprovada por um portfólio
          verificável) que demonstre sua compreensão dos padrões de design e uso
          de guidelines;
        </li>
        <li>
          Com capacidade de transformar problemas complexos em experiências
          simples e de impacto;
        </li>
        <li>
          Que conheça técnicas de análise, documentação, validação, metodologias
          de UX;
        </li>
        <li>
          Que seja capaz de fazer análises de informações baseadas em pesquisas
          e dados;
        </li>
        <li>
          Com teoria e prática de design de interação, usabilidade, arquitetura
          de informação;
        </li>
        <li>
          Que trabalhe com planejamento, aplicação e análise de pesquisas com
          usuários;
        </li>
        <li>
          Que tenha alguma experiência em front-end (HTML5, CSS3). (Não vai
          precisar codar, mas é importante ter conhecimento de como funciona a
          web);
        </li>
      </ul>
      <h4>O que brilharia aos nossos olhos</h4>
      <ul>
        <li>Ter disciplina;</li>
        <li>
          Ser analítico (atento a detalhes, organizado, focado, assertivo);
        </li>
        <li>Ser uma pessoa que está aberta a novas tecnologias;</li>
        <li>Ser adaptável, com facilidade de mudanças;</li>
        <li>
          Ter atitude de dono do negócio, ser despojado e ter jogo de cintura;
        </li>
        <li>Gostar de resolver problemas e ser colaborativo;</li>
        <li>Ser sociável e focado na experiência do cliente.</li>
        <li>Senso de responsabilidade e urgência;</li>
        <li>Experiência trabalhando com metodologias ágeis;</li>
      </ul>
      <h4>Sobre o processo seletivo</h4>
      <ol>
        <li>
          <h5 style={{ fontSize: '1em', marginBottom: '4px' }}>
            Comece a jornada:
          </h5>
          Para iniciar o seu processo, você faz sua inscrição exclusivamente
          através do botão increve-se logo abaixo.
        </li>
        <li>
          <h5 style={{ fontSize: '1em', marginBottom: '4px' }}>
            Mostre o que você sabe:
          </h5>
          Quase lá! Nesta etapa, você será desafiado a criar uma pequena
          interface para validar suas aptidões e competências. Se aprovado no
          desafio, você é selecionado para etapa final.
        </li>
        <li>
          <h5 style={{ fontSize: '1em', marginBottom: '4px' }}>
            Vamos nos conhecer melhor:
          </h5>
          Ufa! Mais um pouquinho… Nesta fase, os responsáveis da área que você
          irá trabalhar fazem uma entrevista com você remotamente, via whereby
          (antigo appearin). Se acreditarmos que você tem alinhamento com o
          time, você é contratado.
        </li>
      </ol>
      <footer className="pb-5">
        <Button
          size="lg"
          target="_blank"
          href="https://forms.gle/hjsaNu8f5yduAoQd8"
        >
          Inscreva-se
        </Button>
        <Link className="ml-3" to="/vagas">
          Ver todas as vagas
        </Link>
      </footer>
    </Container>
  </Layout>
);

Desiner.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Desiner;
